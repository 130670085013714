import { Link } from "gatsby"
import * as React from "react"
import { links } from "../common/constants"

import Layout from "../components/layout"
import Seo from "../components/seo"

const TermsAndConditionsPage = () => (
  <Layout>
    <Seo title="Termini & Condizioni" />

    <section className="py-10 md:py-14 lg:py-24">
      <div className="px-4 mx-auto">
        <div className="max-w-4xl mx-auto">
          <div className="px-4 mb-12 lg:mb-0 text-justify">
            <h1 className="text-4xl lg:text-5xl text-center font-heading mb-10">
              Termini & Condizioni
              <span className="block text-base text-blue-600">
                In vigore dal 20 luglio 2022
              </span>
            </h1>
            <p className=" mb-5">
              Questi termini e condizioni delineano le regole e i regolamenti
              per l'utilizzo del sito Web del Montebello Group S.r.l.s., che si
              trova all'indirizzo{" "}
              <Link
                to={links.home}
                className="text-blue-600 hover:text-blue-500"
              >
                https://www.gruppomontebello.it
              </Link>
              .
            </p>
            <p className=" mb-5">
              Accedendo a questo sito Web assumiamo che accetti questi termini e
              condizioni. Non continuare a utilizzare gruppomontebello.it se non
              accetti di accettare tutti i termini e le condizioni indicati in
              questa pagina.
            </p>
            <p className=" mb-5">
              La seguente terminologia si applica a questi Termini e condizioni,
              Informativa sulla privacy e Avviso di non responsabilità e tutti i
              Contratti: "Cliente", "Tu" e "Tuo" si riferiscono a te, alla
              persona che accede a questo sito Web e conforme ai termini e alle
              condizioni della Società. "La Società", "Noi stessi", "Noi",
              "Nostro" e "Noi", si riferisce alla nostra Società. "Parte",
              "Parti" o "Noi", si riferisce sia al Cliente che a noi stessi.
              Tutti i termini si riferiscono all'offerta, all'accettazione e al
              corrispettivo del pagamento necessario per intraprendere il
              processo della nostra assistenza al Cliente nel modo più
              appropriato per l'espresso scopo di soddisfare le esigenze del
              Cliente in relazione alla fornitura dei servizi dichiarati dalla
              Società, in conformità con e fatta salva la legge vigente in
              Italia. Qualsiasi uso della suddetta terminologia o di altre
              parole al singolare, plurale, maiuscolo e/o lui/lei, sono da
              considerarsi intercambiabili e quindi riferiti alla stessa.
            </p>

            <h2 className="text-3xl lg:text-4xl font-heading my-6">Cookies</h2>
            <p className=" mb-5">
              Utilizziamo l'uso dei cookie. Accedendo a gruppomontebello.it,
              acconsenti all'utilizzo dei cookie in accordo con il Gruppo
              Montebello S.r.l.s.' Politica sulla riservatezza.
            </p>
            <p className=" mb-5">
              La maggior parte dei siti Web interattivi utilizza i cookie per
              consentirci di recuperare i dettagli dell'utente ad ogni visita. I
              cookie vengono utilizzati dal nostro sito Web per abilitare la
              funzionalità di determinate aree in modo da rendere più facile per
              le persone che visitano il nostro sito Web. Anche alcuni dei
              nostri partner affiliati/pubblicitari possono utilizzare i cookie.
            </p>

            <h2 className="text-3xl lg:text-4xl font-heading my-6">Licenza</h2>
            <p className=" mb-5">
              Salvo diversa indicazione, Montebello Group S.r.l.s e/o i suoi
              licenziatari sono titolari dei diritti di proprietà intellettuale
              per tutto il materiale presente su gruppomontebello.it. Tutti i
              diritti di proprietà intellettuale sono riservati. Puoi accedervi
              da gruppomontebello.it per uso personale soggetto alle restrizioni
              stabilite in questi termini e condizioni.
            </p>
            <p className=" mb-2">Non devi:</p>
            <ul className="list-disc list-inside mb-5">
              <li>Ripubblica materiale da gruppomontebello.it</li>
              <li>
                Vendi, noleggia o concede in sublicenza materiale da
                gruppomontebello.it
              </li>
              <li>
                Riprodurre, duplicare o copiare materiale da gruppomontebello.it
              </li>
              <li>Ridistribuire i contenuti da gruppomontebello.it</li>
            </ul>

            <h2 className="text-3xl lg:text-4xl font-heading my-6">
              Collegamento ipertestuale al nostro contenuto
            </h2>
            <p className=" mb-2">
              Le seguenti organizzazioni possono collegarsi al tuo sito Web
              senza previa approvazione scritta:
            </p>
            <ul className="list-disc list-inside mb-5">
              <li>Agenzie governative;</li>
              <li>Motori di ricerca;</li>
              <li>testate giornalistiche;</li>
              <li>
                I distributori di elenchi online possono collegarsi al nostro
                sito Web nello stesso modo in cui collegano ipertestuali ai siti
                Web di altre attività quotate; e
              </li>
              <li>
                Aziende accreditate a livello di sistema, tranne che sollecitano
                organizzazioni senza scopo di lucro, centri commerciali di
                beneficenza e gruppi di raccolta fondi di beneficenza che
                potrebbero non avere collegamenti ipertestuali al nostro sito
                Web.
              </li>
            </ul>
            <p className=" mb-5">
              Queste organizzazioni possono collegarsi alla nostra home page, a
              pubblicazioni o ad altre informazioni del sito Web purché il
              collegamento: (a) non sia in alcun modo ingannevole; (b) non
              implica falsamente sponsorizzazione, approvazione o approvazione
              della parte che collega e dei suoi prodotti e/o servizi; e (c) si
              inserisce nel contesto del sito della parte che collega.
            </p>
            <p className=" mb-2">
              Potremmo prendere in considerazione e approvare altre richieste di
              collegamento dai seguenti tipi di organizzazioni:
            </p>
            <ul className="list-disc list-inside mb-5">
              <li>
                fonti di informazioni comunemente note per consumatori e/o
                aziende;
              </li>
              <li>siti della comunità dot.com;</li>
              <li>
                associazioni o altri gruppi che rappresentano enti di
                beneficenza;
              </li>
              <li>distributori di elenchi on-line;</li>
              <li>portali Internet;</li>
              <li>studi contabili, legali e di consulenza; e</li>
              <li>istituzioni scolastiche e associazioni di categoria.</li>
            </ul>
            <p className=" mb-5">
              Approveremo le richieste di collegamento da queste organizzazioni
              se decidessimo che: (a) il collegamento non ci farebbe apparire
              sfavorevole a noi stessi o alle nostre attività accreditate; (b)
              l'organizzazione non ha alcun record negativo con noi; (c) il
              vantaggio per noi della visibilità del collegamento ipertestuale
              compensa l'assenza di Montebello Group S.r.l.s; e (d) il
              collegamento è nel contesto di informazioni generali sulle
              risorse.
            </p>
            <p className=" mb-5">
              Queste organizzazioni possono collegarsi alla nostra home page
              purché il collegamento: (a) non sia in alcun modo ingannevole; (b)
              non implica falsamente sponsorizzazione, approvazione o
              approvazione della parte che collega e dei suoi prodotti o
              servizi; e (c) si inserisce nel contesto del sito della parte che
              collega.
            </p>
            <p className=" mb-5">
              Se sei una delle organizzazioni elencate al precedente paragrafo 2
              e sei interessato a collegarti al nostro sito web, devi informarci
              inviando una e-mail a Montebello Group S.r.l.s. Includi il tuo
              nome, il nome della tua organizzazione, le informazioni di
              contatto e l'URL del tuo sito, un elenco di tutti gli URL da cui
              intendi collegarti al nostro sito Web e un elenco degli URL sul
              nostro sito a cui desideri accedere collegamento. Attendi 2-3
              settimane per una risposta.
            </p>
            <p className=" mb-2">
              Le organizzazioni approvate possono creare un collegamento
              ipertestuale al nostro sito Web come segue:
            </p>
            <ul className="list-disc list-inside mb-5">
              <li>Utilizzando il nostro nome aziendale; o</li>
              <li>
                Utilizzando il localizzatore di risorse uniforme a cui è
                collegato; o
              </li>
              <li>
                L'uso di qualsiasi altra descrizione del nostro sito Web
                collegata a ciò ha senso nel contesto e nel formato dei
                contenuti sul sito della parte che collega.
              </li>
            </ul>
            <p className=" mb-5">
              Non sarà consentito l'uso del logo di Montebello Group S.r.l.s o
              di altre opere d'arte per il collegamento in assenza di un accordo
              di licenza del marchio.
            </p>

            <h2 className="text-3xl lg:text-4xl font-heading my-6">iFrames</h2>
            <p className=" mb-5">
              Senza previa approvazione e autorizzazione scritta, non è
              possibile creare frame attorno alle nostre pagine Web che alterano
              in alcun modo la presentazione visiva o l'aspetto del nostro sito
              Web.
            </p>

            <h2 className="text-3xl lg:text-4xl font-heading my-6">
              Responsabilità sui contenuti
            </h2>
            <p className=" mb-5">
              We shall not be hold responsible for any content that appears on
              your Website. You agree to protect and defend us against all
              claims that is rising on your Website. No link(s) should appear on
              any Website that may be interpreted as libelous, obscene or
              criminal, or which infringes, otherwise violates, or advocates the
              infringement or other violation of, any third party rights.
            </p>

            <h2 className="text-3xl lg:text-4xl font-heading my-6">
              La tua politica
            </h2>
            <p className=" mb-5">
              Si prega di leggere la nostra Please read our{" "}
              <Link
                to={links.privacyPolicy}
                className="text-blue-600 hover:text-blue-500"
              >
                informativa sulla privacy
              </Link>
              .
            </p>

            <h2 className="text-3xl lg:text-4xl font-heading my-6">
              Riserva dei diritti
            </h2>
            <p className=" mb-5">
              Ci riserviamo il diritto di richiedere la rimozione di tutti i
              collegamenti o di qualsiasi collegamento particolare al nostro
              sito Web. Accetti di rimuovere immediatamente tutti i collegamenti
              al nostro sito Web su richiesta. Ci riserviamo inoltre il diritto
              di modificare questi termini e condizioni e la relativa politica
              di collegamento in qualsiasi momento. Collegandoti continuamente
              al nostro sito Web, accetti di essere vincolato e di seguire
              questi termini e condizioni di collegamento.
            </p>

            <h2 className="text-3xl lg:text-4xl font-heading my-6">
              Rimozione di link dal nostro sito web
            </h2>
            <p className=" mb-5">
              Se trovi un collegamento sul nostro sito Web offensivo per
              qualsiasi motivo, sei libero di contattarci e informarci in
              qualsiasi momento. Prenderemo in considerazione le richieste di
              rimozione dei collegamenti, ma non siamo obbligati a rispondere
              direttamente all'utente.
            </p>
            <p className=" mb-5">
              We do not ensure that the information on this website is correct,
              we do not warrant its completeness or accuracy; nor do we promise
              to ensure that the website remains available or that the material
              on the website is kept up to date.
            </p>

            <h2 className="text-3xl lg:text-4xl font-heading my-6">
              Disclaimer
            </h2>
            <p className=" mb-2">
              Nella misura massima consentita dalla legge applicabile,
              escludiamo tutte le dichiarazioni, garanzie e condizioni relative
              al nostro sito Web e all'utilizzo di questo sito Web. Nulla in
              questo disclaimer:
            </p>
            <ul className="list-disc list-inside mb-5">
              <li>
                limitare o escludere la nostra o la vostra responsabilità per
                morte o lesioni personali;
              </li>
              <li>
                limitare o escludere la nostra o la vostra responsabilità per
                frode o falsa dichiarazione fraudolenta;
              </li>
              <li>
                limitare qualsiasi nostra o vostra responsabilità in qualsiasi
                modo non consentito dalla legge applicabile; o
              </li>
              <li>
                escludere qualsiasi nostra o tua responsabilità che potrebbe non
                essere esclusa dalla legge applicabile.
              </li>
            </ul>
            <p className=" mb-5">
              Le limitazioni ei divieti di responsabilità stabiliti in questa
              Sezione e altrove in questo disclaimer: (a) sono soggetti al
              paragrafo precedente; e (b) disciplinano tutte le responsabilità
              derivanti dall'esclusione di responsabilità, comprese le
              responsabilità derivanti da contratto, atto illecito e violazione
              di obblighi di legge.
            </p>
            <p className=" mb-5">
              As long as the website and the information and services on the
              website are provided free of charge, we will not be liable for any
              loss or damage of any nature.
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default TermsAndConditionsPage
